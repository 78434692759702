.books-page {
  .book-collapse {
    > .ant-collapse-item > .ant-collapse-header {
      padding: 0;
    }
  }

  .carousel-wrapper {
    width: 550px;
    max-width: 100%;
  }

  .ant-carousel {

    &:hover {
      .slick-arrow-wrapper {
        visibility: visible;
        opacity: 1;
      }
    }

    .slick-arrow {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex !important;
      align-items: center;
      justify-content: center;
      font-size: inherit !important;
      color: #fff !important;
      user-select: none;
      margin-top: 0;
    }

    .slick-arrow-wrapper {
      position: absolute;
      top: 0;
      height: 100%;
      z-index: 1;
      color: #fff;
      width: 70px;
      font-size: 25px;
      visibility: hide;
      opacity: 0;
      transition: .2s;

      &-prev {
        left: 0;
        background: linear-gradient(to right, rgba(#000, .7) 10%, rgba(#000, 0) 90%);
      }

      &-next {
        right: 0;
        background: linear-gradient(to left, rgba(#000, .7) 10%, rgba(#000, 0) 90%);
      }
    }
  }
}