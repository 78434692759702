.featured-book {
  position: relative;
  z-index: 0;

  &:hover {
    .featured-book__read {
      visibility: visible;
      opacity: 1;
    }
  }

  &::after {
    content: "";
    display: block;
    padding-top: 66.67%;
  }

  &__banner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text {
    position: absolute;
    height: 90px;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(#000, 0.75);
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    z-index: 2;
  }

  &__title {
    font-size: 18px;
    color: #fff;
  }

  &__read {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(#000, 0.7);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
    visibility: hidden;
    opacity: 0;
  }

  &__link {
    font-size: 16px;
    color: #fff;
  }
}
