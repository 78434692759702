@font-face {
  font-family: "Araboto";
  src: local("Araboto"), url(./assets/fonts/Araboto/Araboto-Black.ttf);
  font-weight: 900;
}

@font-face {
  font-family: "Araboto";
  src: local("Araboto"), url(./assets/fonts/Araboto/Araboto-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "Araboto";
  src: local("Araboto"), url(./assets/fonts/Araboto/Araboto-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Araboto";
  src: local("Araboto"), url(./assets/fonts/Araboto/Araboto-Normal.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Araboto";
  src: local("Araboto"), url(./assets/fonts/Araboto/Araboto-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: "Araboto";
  src: local("Araboto"), url(./assets/fonts/Araboto/Araboto-Thin.ttf);
  font-weight: 200;
}

html,
body {
  height: 100%;
  font-family: "Araboto";
}

ul,
ol {
  list-style: none;
}

p {
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.anticon.ant-menu-item-icon svg {
  width: 20px;
  height: 20px;
}

.ant-menu-submenu-title .anticon {
  width: 20px;
  height: 20px;
}

.anticon.ant-menu-item-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.anticon.ant-menu-item-icon span {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.ant-card-actions--text .ant-card-actions > li > span {
  cursor: text;

  &:hover {
    color: inherit;
  }
}
// .rbc-timeslot-group {
//   min-height: 55px;
// }

:root {
  --plyr-color-main: #f1b24d;
  // --plyr-control-spacing: 5px;
  // --plyr-control-icon-size: 14px;
}

.rbc-event {
  font-size: 13px;
  background-color: transparent !important;
  color: #DB2522 !important;
  border: 1px solid #DB2522 !important;

  &.prepared {
    color: #fff !important;
    background-color: #DB2522 !important;
  }

  &.ended {
    border-color: #138c38 !important;
    background-color: #138c38 !important;
    color: #fff !important;
  }
  &.started {
    border-color: #dbc529 !important;
    background-color: #dbc529 !important;
    color: #fff !important;
  }

  &.rbc-selected {
    color: inherit;
    outline: 0;
  }
}

input[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
}
