.dashboard {
  &-progress-wrapper {
    display: inline-block;
    width: 200px;
  }

  .ant-progress-outer {
    padding-right: 0 !important;
  }

  .ant-progress-status-success .ant-progress-text {
    color: #db2522;
  }

  .ant-progress-text {
    position: absolute;
    bottom: calc(100% - 3px);
    left: 0;
    margin: 0;
  }
}
