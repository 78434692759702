.audio-player {
  flex: 1;
  display: flex;
  align-items: center;

  &__play-pause {
    display: flex;
    align-items: center;
  }

  &__name {
    padding: 0 15px;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__waveform {
    flex: 1;
    flex-shrink: 0;
  }
}
