.gallery-card {
  display: inline-block;
  width: 320px;

  &:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  &__media-wrapper {
    margin-bottom: 5px;
  }

  &__image-wrapper {
    position: relative;

    &::after {
      content: "";
      display: block;
      padding-top: 56.25%;
    }
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__name {
    margin-bottom: 3px;
  }

  &__class-name {
    font-weight: 700;
    font-size: 16px;
  }

  &__date {
    color: rgba(#000, .5);
  }
}