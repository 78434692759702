.vocabulary-card {
  width: 121px;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 35px;
    margin-bottom: 20px;
  }

  &__image-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 3px 15px rgba(#000, 0.1);

    &::after {
      content: "";
      display: block;
      padding-top: calc(188 / 121 * 100%);
    }
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
