.video-player {
  &__thumbnail-wrapper {
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      padding-top: 56.25%;
    }
  }

  &__thumbnail {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}