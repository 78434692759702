.book-thumbnail {
  &__cover {
    position: relative;
    &::after {
      content: "";
      display: block;
      padding-top: 66.67%;
    }
  }

  &__cover-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
}