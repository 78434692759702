.class-lesson-details {
  .lesson-outline {
    .ant-list:not(:last-child) {
      margin-bottom: 15px;
    }

    .ant-list-header {
      padding: 0 !important;
    }

    .ant-checkbox-wrapper {
      flex: 1;

      span:nth-child(2) {
        flex: 1;
        display: flex;
      }
    }
  }

  .lesson-attendees {
    .ant-list-item-action {
      margin-left: 20px;
    }
  }

  img {
    max-width: 100%;
  }
}
