.lesson-select {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .ant-card-body {
    padding: 10px;
  }

  img {
    max-width: 200px;
    max-height: 120px;
  }
}