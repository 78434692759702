.messages-page {
  &__wrapper {
    height: calc(100vh - 64px);
    padding: 15px;
  }

  &__chat-list {
    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
      white-space: nowrap;
      overflow: hidden;
      margin-right: 20px;
      text-overflow: ellipsis;
    }

    .ant-tabs-content-holder {
      overflow: auto;
    }
  }

  &__chat-form {
    background-color: #fff;
  }
}
