.message {
  display: flex;
  margin-bottom: 7px;

  &-content {
    max-width: 300px;
    padding: 6px 18px;
    border-radius: 10px;
    line-break: anywhere;

    .message-parent & {
      background-color: #fbc02d;
    }

    .message-teacher & {
      color: #fff;
    }
  }

  &-teacher {
    justify-content: flex-end;
  }

  &__name {
    width: 0;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 7px;
  }
}
